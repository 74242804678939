<template>
  <div class="game-container">
    <LearnReview
      :imgList="imgList"
      :wordsList="wordsList"
      :sentenceList="sentenceList"
      :lessonNum="20"
      @deleteWords="handleDeleteWords"
    />
  </div>
</template>

<script>
import LearnReview from "@/components/Course/GamePage/LearnReviewGame";
export default {
  data() {
    return {
      imgList: [
        {
          id: 1,
          imgUrl: require("@/assets/img/18-Blue-review/lesson-20-piezhe-yellow.svg"),
          show: true,
          type: 3,
        },
        {
          id: 2,
          imgUrl: require("@/assets/img/18-Blue-review/lesson-20-shen-white.svg"),
          show: true,
          type: 3,
        },
        {
          id: 3,
          imgUrl: require("@/assets/img/18-Blue-review/lesson-20-me-white.svg"),
          show: true,
          type: 3,
        },
      ],
      wordsList: [
        {
          id: 1,
          name: "nǎ",
          namePin: "哪",
          show: true,
          type: 1,
        },
        {
          id: 2,
          name: "guó",
          namePin: "国",
          show: true,
          type: 1,
        },
        {
          id: 3,
          name: "Zhōngguó rén",
          namePin: "中国人",
          show: true,
          type: 1,
        },
        {
          id: 4,
          name: "shénme",
          namePin: "什么",
          show: true,
          type: 1,
        },
      ],
      sentenceList: [
        {
          id: 1,
          name: "Nǐ shì nǎ guó rén?",
          namePin: "你是哪国人？",
          show: true,
          type: 2,
        },
      ],
    };
  },
  components: {
    LearnReview,
  },
  methods: {
    handleDeleteWords({ type, id }) {
      if (type === 1) {
        this.wordsList[id - 1].show = false;
      } else if (type === 2) {
        this.sentenceList[id - 1].show = false;
      } else {
        this.imgList[id - 1].show = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.game-container {
  width: 100%;
  height: 100%;
}
</style>
